/*global $*/
/*eslint no-undef: "error"*/

export default class Partner {
    classItem= 'partner--item'
    classButton= 'partner--button'

    classOpen = 'partner-list-open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            console.log('work 123')
            $(this).closest(`.${self.classItem}`).addClass(self.classOpen)
        })
    }
}
