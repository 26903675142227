/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'
import form from '@/store/form'
import './assets/js/css/ImportCss'
import tippy from 'tippy.js'
import { Fancybox } from '@fancyapps/ui'
import AppForm from '@/components/Form/AppForm.vue'
import SwiperSlider from '@/assets/js/Swiper/Swiper'
import MenuMobile from '@/assets/js/Menu/Mobile/MenuMobile'
import Toggle from '@/assets/js/Toggle/Toggle'
import Review from '@/assets/js/Review/Review'
import Show from '@/assets/js/Show/Show'
import Map from '@/assets/js/Map/Map'
import Faq from '@/assets/js/Faq/Faq'
import ButtonPrice from '@/assets/js/Button/Price/ButtonPrice'
import ButtonService from '@/assets/js/Button/Service/ButtonService'
import ButtonForm from '@/assets/js/Button/Form/ButtonForm'
import HeaderFixed from '@/assets/js/Header/Fixed/HeaderFixed'
import Partner from '@/assets/js/Partner/Partner'
import Price from '@/assets/js/Price/Price'
import Brands from '@/assets/js/Brands/Brands'

// Подключаем глобально jQuery
const $ = window.$ = window.jQuery = require('jquery')

document.addEventListener('DOMContentLoaded', () => {
    tippy('.button-icon-content-bottom', {
        theme: 'light',
        arrow: true,
        maxWidth: 1000,
        placement: 'bottom-start',
        animation: 'scale-extreme',
        content(reference) {
            const id = reference.getAttribute('data-template');
            const template = document.getElementById(id);
            return template.innerHTML;
        },
        allowHTML: true
    })

    Fancybox.bind('[data-fancybox]', {
        mainClass: 'fancybox--popup'
    })

    new SwiperSlider()
    new MenuMobile()
    new Toggle()
    new Review()
    new Show()
    new Map()
    new Faq()
    new ButtonPrice()
    new ButtonService()
    new ButtonForm()
    new HeaderFixed()
    new Partner()
    new Price()
    new Brands()

    if(document.getElementById('block-form--master') != null) {
        createApp({})
            .component('form-master', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block-form--master')
    }

    if(document.getElementById('block-form--master-block') != null) {
        createApp({})
            .component('form-master', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block-form--master-block')
    }

    if(document.getElementById('block-form--master-line') != null) {
        createApp({})
            .component('form-master', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block-form--master-line')
    }

    if(document.getElementById('block-form--master-popup') != null) {
        createApp({})
            .component('form-master', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block-form--master-popup')
    }

    if(document.getElementById('block-form--exit-popup') != null) {
        createApp({})
            .component('form-master', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block-form--exit-popup')
    }

    if(document.getElementById('block-form--crash-popup') != null) {
        createApp({})
            .component('form-master', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block-form--crash-popup')
    }
})
