/*global $*/
/*eslint no-undef: "error"*/

export default class Price {
    classItem = 'price--item'
    classButton = 'price--button'
    classText = 'price--text'

    classOpen = 'open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let textTo = $(this).data('to')
            let textDo = $(this).data('do')

            $(this).closest(`.${self.classItem}`).toggleClass(self.classOpen)

            if ($(this).closest(`.${self.classItem}`).hasClass(self.classOpen))
                $(this).closest(`.${self.classItem}`).find(`.${self.classText}`).text(textDo)
            else
                $(this).closest(`.${self.classItem}`).find(`.${self.classText}`).text(textTo)
        })
    }
}
