/*global $*/
/*eslint no-undef: "error"*/
export default class Toggle {
    classItem = 'toggle--item'
    classButton = 'toggle--button'

    classOpen = 'open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(`.${self.classItem}`).toggleClass(self.classOpen)
        })
    }
}
