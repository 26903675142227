import Swiper from 'swiper'
import {Autoplay, EffectCoverflow, Navigation, Pagination, Thumbs} from 'swiper/modules'
Swiper.use([Navigation, Pagination, EffectCoverflow, Autoplay, Thumbs])

export default class SwiperSlider {

    createPartner () {
        return new Swiper('#slider--partner', {
            slidesPerView: 6,
            spaceBetween: 0,
            loop: true,
            navigation: {
                nextEl: '.slider--partner-next',
                prevEl: '.slider--partner-prev',
            },
            pagination: {
                el: '.swiper-pagination--partner',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                },
                320: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                },
                360: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                },
                375: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                },
                414: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                },
                480: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                },
                514: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                },
                640: {
                    slidesPerView: 4,
                    spaceBetween: 0,
                },
                768: {
                    slidesPerView: 5,
                    spaceBetween: 0,
                },
                991: {
                    slidesPerView: 5,
                    spaceBetween: 0,
                },
                1024: {
                    slidesPerView: 6,
                    spaceBetween: 0,
                },
                1240: {
                    slidesPerView: 6,
                    spaceBetween: 0,
                },
                1440: {
                    slidesPerView: 6,
                    spaceBetween: 0,
                }
            }
        })
    }

    createReviewVideo () {
        return new Swiper('#slider--review-video', {
            slidesPerView: 2,
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--review-video-next',
                prevEl: '.slider--review-video-prev',
            },
            pagination: {
                el: '.swiper-pagination--review-video',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                514: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1240: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                }
            }
        })
    }

    createReviewText () {
        return new Swiper('#slider--review-text', {
            slidesPerView: 2,
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--review-text-next',
                prevEl: '.slider--review-text-prev',
            },
            pagination: {
                el: '.swiper-pagination--review-text',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                514: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                991: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1240: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                }
            }
        })
    }

    createSert () {
        return new Swiper('#slider--sert', {
            slidesPerView: 5,
            spaceBetween: 15,
            navigation: {
                nextEl: '.slider--sert-next',
                prevEl: '.slider--sert-prev',
            },
            pagination: {
                el: '.swiper-pagination--sert',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    spaceBetween: 5,
                },
                320: {
                    slidesPerView: 2,
                    spaceBetween: 5,
                },
                360: {
                    slidesPerView: 2,
                    spaceBetween: 5,
                },
                375: {
                    slidesPerView: 2,
                    spaceBetween: 5,
                },
                414: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 4,
                    spaceBetween: 10
                },
                991: {
                    slidesPerView: 4,
                    spaceBetween: 15
                },
                1024: {
                    slidesPerView: 5,
                    spaceBetween: 15,
                },
                1240: {
                    slidesPerView: 5,
                    spaceBetween: 15,
                },
                1440: {
                    slidesPerView: 5,
                    spaceBetween: 15,
                }
            }
        })
    }

    createProp () {
        return new Swiper('#slider--prop', {
            slidesPerView: 5,
            spaceBetween: 10,
            navigation: {
                nextEl: '.slider--prop-next',
                prevEl: '.slider--prop-prev',
            },
            pagination: {
                el: '.swiper-pagination--prop',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                991: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                },
                1240: {
                    slidesPerView: 5,
                    spaceBetween: 10,
                },
                1440: {
                    slidesPerView: 5,
                    spaceBetween: 10,
                }
            }
        })
    }

    createWt () {
        return new Swiper('#slider--review-wt', {
            slidesPerView: 5,
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--wt-next',
                prevEl: '.slider--wt-prev',
            },
            pagination: {
                el: '.swiper-pagination--review-wt',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                360: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                480: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                514: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                },
                991: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                },
                1240: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                }
            }
        })
    }

    constructor () {
        if(document.getElementById('slider--partner')!= null) {
            this.createPartner()
        }

        if(document.getElementById('slider--review-video')!= null) {
            this.createReviewVideo()
        }

        if(document.getElementById('slider--review-text')!= null) {
            this.createReviewText()
        }

        if(document.getElementById('slider--sert')!= null) {
            this.createSert()
        }

        if(document.getElementById('slider--prop')!= null) {
            this.createProp()
        }

        if(document.getElementById('slider--review-wt')!= null) {
            this.createWt()
        }
    }
}
